<template>
    <div>
        <v-stepper
            v-model="e1"
            v-if="!datiInviati && this.mode == 'form' && !error"
        >
            <v-stepper-header>
                <v-stepper-step :complete="e1 > 0" step="0">
                    Dati contraente
                </v-stepper-step>
                <v-stepper-step :complete="e1 > 1" step="1">
                    Dati contraente
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 2" step="2">
                    Consensi 1/2
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step :complete="e1 > 3" step="3">
                    Consensi 2/2
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="4"> Veicoli </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="0">
                    <v-card class="mb-12" color="grey lighten-5">
                        <v-card-title cy-data="informazion-header"
                            >Informazioni preventive per la gestione della sua
                            richiesta di quotazione assicurativa per i veicoli
                            di interesse storico.</v-card-title
                        >
                        <v-card-text>
                            <p>Gentilissimo Cliente,</p>
                            <p>
                                se sei arrivato a questa pagina è perchè hai
                                ricevuto da parte di un nostro incaricato il
                                link di accesso dovuto a una prima valutazione
                                positiva dei modelli di veicolo storico che hai
                                comunicato: quindi in base ai criteri di
                                assumibilità della convenzione assicurativa che
                                proponiamo, il tuo/i tuoi veicoli hanno le
                                caratteristiche necessarie per beneficiare delle
                                condizioni previste.
                            </p>
                            <p>
                                Per maggiore precisione indichiamo di seguito le
                                tipologie di veicoli che NON possono beneficiare
                                della convenzione assicurativa proposta:
                            </p>
                            <ul>
                                <li>
                                    veicoli non iscritti o con iscrizione
                                    scaduta a Club Veicoli Storici
                                </li>
                                <li>veicoli con meno di 20 anni di età</li>
                                <li>
                                    autovetture di tipo Utilitaria con meno di
                                    30 di età
                                </li>
                                <li>
                                    autovetture di tipo Station Wagon con meno
                                    di 30 anni di età
                                </li>
                                <li>fuoristrada con meno di 35 anni di età</li>
                                <li>
                                    scooter/ciclomotori con meno di 30 anni di
                                    età
                                </li>
                                <li>motocicli con meno di 25 anni di età</li>
                            </ul>
                            <p>
                                Per casi specifici la richiesta sarà valutata
                                nel caso specifico.
                            </p>
                        </v-card-text>
                        <v-card-title>Prossimi passi</v-card-title>
                        <v-card-text>
                            <p>
                                Per portare a termine il processo di regolare
                                emissione delle garanzie che fanno parte della
                                convenzione assicurativa proposta dovremo
                                necessariamente scambiarci delle informazioni e
                                della documentazione in maniera reciproca,
                                attività che saranno totalmente svolte in questo
                                portale. Da parte tua dovrai compilare con
                                precisione le tue informazioni anagrafiche e i
                                dati dei tuoi veicoli storici, allegando i
                                documenti necessari ma prima di tutto dovrai
                                consultare tutta la documentazione di
                                informativa pre-contrattuale in cui sono
                                indicate tutte le condizioni contrattuali e
                                tutte le informazioni riguardanti
                                l'intermediario assicurativo che ti sta
                                proponendo questa soluzione assicurativa: è
                                molto importante che tu sia consapevole dei
                                contenuti e delle limitazione dei contratti che
                                ti saranno proposti quindi ti preghiamo di
                                prestare molta attenzione nella fase di
                                pre-valutazione.
                            </p>
                            <p>
                                Per darti una prima panoramica di sintesi, MA
                                CHE NON SOSTITUISCE IN NESSUN CASO LA
                                DOCUMENTAZIONE DI INFORMATIVA PRE-CONTRATTUALE,
                                ti rendiamo disponibile a questo link il
                                documento "Descrizione offerta GoOld"
                            </p>

                            <p>
                                Di seguito indichiamo tutte le fasi previste e
                                al termine di queste informazioni potrai
                                scegliere se proseguire o se abbandonare la
                                richiesta: in tal caso la richiesta sarà
                                cancellata dai nostri sistemi e nessun dato a te
                                riconducibile sarà salvato nè utilizzato.
                            </p>

                            <ul>
                                <li>
                                    Consultazione dell'informativa
                                    pre-contrattuale dell'intermediario
                                    assicurativo che ti sta proponendo i
                                    contratti e dei set informativi dei prodotti
                                    contenuti nell'offerta assicurativa
                                </li>
                                <li>
                                    Consultazione dell' informativa sul
                                    trattamento dei dati e conseguente
                                    accettazione/diniego
                                </li>

                                <li>
                                    Accettazione dell'invio della documentazione
                                    in via telematica (al momento la modalità di
                                    trasmissione cartacea non è prevista) e
                                    delle condizioni generali del servizio per
                                    l' utilizzo della firma elettronica avanzata
                                </li>

                                <li>
                                    Inserimento dei tuoi dati anagrafici e dei
                                    dati riferiti ai veicoli da assicurare e
                                    compilazione del questionario sulle tue
                                    esigenze assicurative in relazione
                                    all'offerta assicurativa, per valutare la
                                    coerenza della proposta: in questa fase sarà
                                    evidenziato se le tue aspettative ed
                                    esigenze sono soddisfatte o meno dalla
                                    soluzione proposta e in caso di esito
                                    negativo, l'offerta sarà declinata.
                                </li>

                                <li>
                                    Caricamento sul portale dei documenti
                                    necessari: documento di riconoscimento,
                                    codice fiscale, libretto/i del/i veicoli da
                                    assicurare e/o altra documentazione
                                    necessaria.
                                </li>

                                <li>
                                    A seguito della valutazione della
                                    documentazione e delle indicazioni fornite
                                    sul questionario di valutazione delle
                                    esigenze assicurative potranno esserci due
                                    esiti:
                                </li>
                                <ul>
                                    <li>
                                        Invio da parte nostra
                                        dell'offerta/preventivo definitivo
                                    </li>
                                    <li>
                                        Invio di comunicazione di esito negativo
                                        qualora, a seguito della verifica
                                        documentale, si verificassero
                                        discrepanze, anomalie o veicoli non
                                        rientranti nella categoria di veicolo di
                                        interesse storico oppure se nella
                                        valutazione delle esigenze assicurative
                                        non si riscontrasse la coerenza
                                        necessaria con la soluzione proposta.
                                    </li>
                                </ul>

                                <li>
                                    In caso di valutazione positiva ed invio del
                                    preventivo definitivo, si potrà procedere
                                    con la richiesta di emissione del contratto
                                    e il pagamento dell'importo comunicato sul
                                    preventivo: caricare copia del pagamento
                                    effettuato ed inviare.
                                </li>

                                <li>
                                    Emissione dei documenti contrattuali: al
                                    ricevimento del pagamento sarà emessa tutta
                                    la documentazione contrattuale e sarà resa
                                    disponibile a seguito di sottoscrizione
                                    telematica tramite OTP, cioè tramite la
                                    ricezione di un codice univoco tramite
                                    messaggio sul proprio dispositivo telefonico
                                    e completamento dell'operazione sul portale.
                                    In questo modo l'invio della documentazione
                                    completa avverrà sulla mail indicata in fase
                                    di inserimento dei dati anagrafici.
                                </li>
                            </ul>
                            BUON VIAGGIO!!!!
                        </v-card-text>
                    </v-card>
                    <v-btn
                        data-cy="btn-informazioni-preventive"
                        color="primary"
                        @click="goToStep(1)"
                    >
                        Avanti
                    </v-btn>
                </v-stepper-content>
                <v-stepper-content step="1">
                    <v-card class="mb-12" color="grey lighten-5">
                        <v-card-text>
                            <v-card-title>Dati anagrafici</v-card-title>
                            <v-form
                                ref="form1"
                                v-model="form1Valid"
                                lazy-validation
                            >
                                <v-radio-group
                                    data-cy="quotazione.persona"
                                    v-model="quotazione.persona"
                                    row
                                    required
                                    :rules="required"
                                >
                                    <v-radio value="f" mandatory>
                                        <template v-slot:label>
                                            <div cy-data="Persona Fisica">
                                                Persona Fisica
                                            </div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="g">
                                        <template v-slot:label>
                                            <div cy-data="Persona Giuridica">
                                                Persona Giuridica
                                            </div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                <div v-if="quotazione.persona">
                                    <v-radio-group
                                        data-cy="quotazione.sesso"
                                        v-model="quotazione.sesso"
                                        row
                                        required
                                        v-if="quotazione.persona == 'f'"
                                        :rules="requiredF"
                                    >
                                        <v-radio value="M" mandatory>
                                            <template v-slot:label>
                                                <div
                                                    cy-data="quotazione.sesso.m"
                                                >
                                                    Maschio
                                                </div>
                                            </template>
                                        </v-radio>
                                        <v-radio value="F">
                                            <template v-slot:label>
                                                <div
                                                    cy-data="quotazione.sesso.f"
                                                >
                                                    Femmina
                                                </div>
                                            </template>
                                        </v-radio>
                                    </v-radio-group>
                                    <v-text-field
                                        data-cy="quotazione.nome"
                                        v-model="quotazione.nome"
                                        label="Nome"
                                        :rules="requiredF"
                                        v-if="
                                            quotazione.persona == 'f' &&
                                            quotazione.persona
                                        "
                                    ></v-text-field>
                                    <v-text-field
                                        data-cy="quotazione.cognome"
                                        v-model="quotazione.cognome"
                                        label="Cognome"
                                        :rules="requiredF"
                                        v-if="
                                            quotazione.persona == 'f' &&
                                            quotazione.persona
                                        "
                                    ></v-text-field>
                                    <v-text-field
                                        data-cy="quotazione.rsoc"
                                        v-model="quotazione.rsoc"
                                        label="Ragione Sociale"
                                        :rules="requiredG"
                                        v-if="
                                            quotazione.persona == 'g' &&
                                            quotazione.persona
                                        "
                                    ></v-text-field>

                                    <template>
                                        <div>
                                            <div class="mb-6" v-if="false">
                                                Active picker:
                                                <code>{{
                                                    activePicker || "null"
                                                }}</code>
                                            </div>
                                            <v-menu
                                                ref="menu"
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            quotazione.dataNascita
                                                        "
                                                        label="Data di nascita"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="
                                                        quotazione.dataNascita
                                                    "
                                                    :active-picker.sync="
                                                        activePicker
                                                    "
                                                    :max="
                                                        (
                                                            new Date().getFullYear() -
                                                            17
                                                        ).toString()
                                                    "
                                                    min="1923-01-01"
                                                    locale="it-it"
                                                    :rules="requiredF"
                                                    @change="saveDate"
                                                ></v-date-picker>
                                            </v-menu>
                                        </div>
                                    </template>

                                    <teo-autocomplete
                                        v-model="quotazione.nazione_nascita_id"
                                        label="Nazione di nascita"
                                        item-text="nome_it"
                                        item-value="id"
                                        server-side-search
                                        resource="/api/autocomplete/nazione"
                                        :rules="requiredF"
                                        v-if="
                                            quotazione.persona == 'f' &&
                                            quotazione.persona
                                        "
                                    >
                                    </teo-autocomplete>
                                    <teo-autocomplete
                                        v-model="
                                            quotazione.provincia_nascita_codice
                                        "
                                        label="Provincia di nascita"
                                        item-text="nome"
                                        item-value="codice"
                                        server-side-search
                                        resource="/api/autocomplete/province"
                                        :rules="requiredF"
                                        v-if="
                                            quotazione.persona == 'f' &&
                                            quotazione.persona &&
                                            quotazione.nazione_nascita_id == 1
                                        "
                                    >
                                    </teo-autocomplete>
                                    <teo-autocomplete
                                        v-model="quotazione.comune_nascita_id"
                                        label="Comune di nascita"
                                        item-text="nome"
                                        item-value="codice"
                                        server-side-search
                                        resource="/api/autocomplete/comune"
                                        :rules="requiredF"
                                        v-if="
                                            quotazione.persona == 'f' &&
                                            quotazione.persona &&
                                            quotazione.nazione_nascita_id == 1
                                        "
                                    >
                                    </teo-autocomplete>
                                    <v-text-field
                                        v-model="quotazione.piva"
                                        label="Partita Iva"
                                        v-if="
                                            quotazione.persona == 'g' &&
                                            quotazione.persona
                                        "
                                        :rules="requiredG"
                                    ></v-text-field>
                                    <v-text-field
                                        data-cy="quotazione.cfisc"
                                        v-model="quotazione.cfisc"
                                        label="Codice Fiscale"
                                        :rules="required"
                                    ></v-text-field>
                                    <v-text-field
                                        data-cy="quotazione.email"
                                        v-model="quotazione.email"
                                        label="E-mail"
                                        :rules="required"
                                    ></v-text-field>
                                    <v-text-field
                                        data-cy="quotazione.telefono"
                                        v-model="quotazione.telefono"
                                        label="Telefono (+39)"
                                        :rules="ruleTelefono"
                                    ></v-text-field>
                                    <v-text-field
                                        data-cy="quotazione.club"
                                        v-model="quotazione.club"
                                        label="Club"
                                        :rules="required"
                                    ></v-text-field>

                                    <v-select
                                        :items="attivita_lavorativa_items"
                                        data-cy="quotazione.attivita_lavorativa"
                                        v-model="quotazione.attivita_lavorativa"
                                        label="Attività lavorativa"
                                    ></v-select>

                                    <v-divider inset></v-divider>

                                    <v-card-title>{{ resSede }}</v-card-title>
                                    <v-text-field
                                        data-cy="quotazione.residenzaIndirizzo"
                                        v-model="quotazione.residenzaIndirizzo"
                                        label="Indirizzo"
                                        :rules="required"
                                    ></v-text-field>
                                    <v-text-field
                                        data-cy="quotazione.residenzaCivico"
                                        v-model="quotazione.residenzaCivico"
                                        label="Civico"
                                        :rules="required"
                                    ></v-text-field>
                                    <v-text-field
                                        data-cy="quotazione.residenzaCap"
                                        v-model="quotazione.residenzaCap"
                                        label="Cap"
                                        :rules="required"
                                        :maxlength="5"
                                    ></v-text-field>
                                    <teo-autocomplete
                                        data-cy="quotazione.residenza_comune_id"
                                        v-model="quotazione.residenza_comune_id"
                                        label="Comune"
                                        item-text="nome"
                                        :rules="required"
                                        item-value="codice"
                                        server-side-search
                                        resource="/api/autocomplete/comune"
                                    >
                                    </teo-autocomplete>

                                    <teo-autocomplete
                                        label="Provincia"
                                        item-text="nome"
                                        item-value="codice"
                                        server-side-search
                                        resource="/api/autocomplete/province"
                                        data-cy="quotazione.provincia_codice"
                                        v-model="quotazione.provincia_codice"
                                        autocomplete="off"
                                        disabled
                                    >
                                    </teo-autocomplete>

                                    <v-divider inset></v-divider>
                                    <v-menu
                                        :close-on-content-click="true"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-text-field
                                                v-model="quotazione.decorrenza"
                                                label="Data presunta di decorrenza"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                :rules="required"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            data-cy="quotazione.decorrenza"
                                            v-model="quotazione.decorrenza"
                                            locale="it-it"
                                        ></v-date-picker>
                                    </v-menu>
                                    <p>
                                        La decorrenza avrà effetto dalle ore 24
                                        del giorno di ricezione del pagamento
                                    </p>
                                </div>
                                <v-card-title
                                    >Trattamento dati personali</v-card-title
                                >
                                <v-checkbox
                                    data-cy="quotazione.consenso_trattamento"
                                    v-model="quotazione.consenso_trattamento"
                                    :rules="required"
                                    ><template v-slot:label>
                                        <div>
                                            Il cliente dichiara di aver
                                            consultato e preso visione del
                                            modulo:
                                            <a href="#"
                                                >Informativa sul trattamento dei
                                                dati personali</a
                                            >
                                            in ottemperanza al Reg. UE 679/2016
                                        </div>
                                    </template>
                                </v-checkbox>
                                <p>Il cliente dichiara:</p>
                                <v-radio-group
                                    data-cy="quotazione.radio1"
                                    v-model="quotazione.radio1"
                                    row
                                    required
                                    :rules="radioRules"
                                >
                                    <template v-slot:label>
                                        <div>
                                            Esprimo il consenso al trattamento
                                            dei dati idonei a rivelare dati
                                            particolari per le finalità connesse
                                            alla sottoscrizione del contratto.
                                        </div>
                                    </template>
                                    <v-radio value="ok" mandatory>
                                        <template v-slot:label>
                                            <div>Do il consenso</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>Nego il consenso</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                <!-- consenso_marketing quotazione.radio2 -->
                                <v-radio-group
                                    id="consenso_marketing"
                                    data-cy="quotazione.radio2"
                                    v-model="quotazione.radio2"
                                    row
                                    required
                                    :rules="required"
                                >
                                    <template v-slot:label>
                                        <div>
                                            Esprimo il consenso al trattamento
                                            dei miei dati per le finalità di
                                            marketing sopra indicate: invio di
                                            comunicazioni
                                            commerciali/promozionali, tramite
                                            modalità automatizzate di contatto
                                            (come e-mail, sms o mms) e
                                            tradizionali (come telefonate con
                                            operatore e posta tradizionale) sui
                                            propri prodotti e servizi,
                                            segnalazione di eventi aziendali,
                                            rilevazione del grado di
                                            soddisfazione della clientela,
                                            nonché realizzazione di indagini di
                                            mercato ed analisi statistiche
                                        </div>
                                    </template>
                                    <v-radio value="ok">
                                        <template v-slot:label>
                                            <div>Do il consenso</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>Nego il consenso</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>

                                <!-- consenso_profilazione
 quotazione.radio3 -->
                                <v-radio-group
                                    id="consenso_profilazione"
                                    data-cy="quotazione.radio3"
                                    v-model="quotazione.radio3"
                                    row
                                    required
                                    :rules="required"
                                >
                                    <template v-slot:label>
                                        <div>
                                            Esprimo il consenso al trattamento
                                            automatizzato dei miei dati
                                            personali, ivi inclusa la
                                            profilazione, effettuato per
                                            analizzare le mie preferenze,
                                            abitudini, interessi (…) al fine di
                                            ricevere comunicazioni commerciali
                                            personalizzate
                                        </div>
                                    </template>
                                    <v-radio value="ok">
                                        <template v-slot:label>
                                            <div>Do il consenso</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>Nego il consenso</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </v-form>
                        </v-card-text>
                    </v-card>
                    <v-btn text class="mr-4"> Annulla </v-btn>
                    <v-btn color="primary" @click="salvaClienteAndGoToStep(2)">
                        Avanti
                    </v-btn>
                    <!-- :disabled="!step.valid" -->
                </v-stepper-content>
                <v-stepper-content step="2">
                    <v-card class="mb-12" color="grey lighten-5">
                        <v-card-text>
                            <v-form ref="form2" lazy-validation>
                                <v-card-title>Precontrattuale</v-card-title>

                                <v-checkbox
                                    id="quotazione.consenso_allegati"
                                    data-cy="quotazione.consenso_allegati"
                                    v-model="quotazione.consenso_allegati"
                                    :rules="required"
                                    ><template v-slot:label>
                                        <div>
                                            *Il cliente dichiara di aver
                                            consultato e preso visione dei
                                            moduli:
                                            <a href="#" @click.stop
                                                >Allegato 3</a
                                            >, <a href="#">Allegato 4</a>,
                                            <a href="#">Allegato 4 TER</a>,
                                            <a href="#"
                                                >Informativa precontrattuale
                                                relative ai contratti stipulati
                                                a distanza</a
                                            >,
                                            <a href="#"
                                                >Lettera incarico Broker</a
                                            >,

                                            <a
                                                :href="
                                                    VUE_APP_URL +
                                                    '/docs/set_informativo_verti_veicoli_storici.pdf'
                                                "
                                                @click.stop
                                                target="_blank"
                                                >Set informativo VERTI
                                                Assicurazioni SpA</a
                                            >,
                                            <a
                                                :href="
                                                    VUE_APP_URL +
                                                    '/docs/set_informativo_am_trust_privacy.pdf'
                                                "
                                                @click.stop
                                                target="_blank"
                                                >Set informativo AmTrust
                                                Assicurazioni SpA</a
                                            >,
                                            <a
                                                :href="
                                                    VUE_APP_URL +
                                                    '/docs/informativa_infodrive_spa.pdf'
                                                "
                                                @click.stop
                                                target="_blank"
                                                >Informativa Infodrive SpA</a
                                            >
                                        </div>
                                    </template>
                                </v-checkbox>

                                <v-card-title
                                    >Modalità di invio
                                    documentazione</v-card-title
                                >

                                <v-checkbox
                                    id="quotazione.consenso_otp"
                                    data-cy="quotazione.consenso_otp"
                                    v-model="quotazione.consenso_otp"
                                    :rules="required"
                                >
                                    <template v-slot:label>

                                        <!-- INIZIO -->
                                        <v-card-text>
    <v-card-title>Precontrattuale</v-card-title>
    <div>
      *Il cliente dichiara di aver consultato e preso visione dei moduli:
      <a target="_blank" :href="VUE_APP_API_URL + '/docs/1 -Allegato 3  4 - 4ter STORICHE 4-23.pdf'" >Allegato 3/4/4 Ter.</a>,
      <!-- <a :href="VUE_APP_API_URL + '/docs/allegato_4.pdf'">Allegato 4</a>,
      <a :href="VUE_APP_API_URL + '/docs/allegato_4_ter.pdf'">Allegato 4 TER</a>, -->
      <a
        :href="
          VUE_APP_API_URL + '/docs/informativa_precontrattuale_contratti_distanza.pdf'
        "
      >
        Informativa precontrattuale relative ai contratti stipulati a distanza </a
      >,
      <a :href="VUE_APP_API_URL + '/docs/lettera_incarico_broker.pdf'"
        >Lettera incarico Broker</a
      >,
      <a
        :href="VUE_APP_API_URL + '/docs/set_informativo_verti_veicoli_storici.pdf'"
        @click.stop
        target="_blank"
      >
        Set informativo VERTI Assicurazioni SpA </a
      >,
      <a
        :href="VUE_APP_API_URL + '/docs/set_informativo_am_trust_privacy.pdf'"
        @click.stop
        target="_blank"
      >
        Set informativo AmTrust Assicurazioni SpA </a
      >,
      <a
        :href="VUE_APP_API_URL + '/docs/informativa_infodrive_spa.pdf'"
        @click.stop
        target="_blank"
      >
        Informativa Infodrive SpA
      </a>
    </div>
</v-card-text>

<!-- FINE -->
</template>
                                </v-checkbox>
                            </v-form>
                        </v-card-text>
                    </v-card>
                    <v-btn color="primary" @click="goToStep(1)">
                        Indietro
                    </v-btn>
                    <v-btn text> Annulla </v-btn>
                    <v-btn color="primary" @click="goToStep(3)"> Avanti </v-btn>
                </v-stepper-content>
                <v-stepper-content step="3">
                    <v-card class="mb-12" color="grey lighten-5">
                        <v-card-text>
                            <v-form ref="form3" lazy-validation>
                              
    <v-card-title>Modalità di invio documentazione</v-card-title>

    <div>
      La formula assicurativa proposta prevede l’invio della documentazione in modalità
      telematica, quindi senza invio di tutta la documentazione in modalità cartacea. Il
      cliente dichiara di aver consultato e preso visione del modulo:
      <a
        :href="
          VUE_APP_API_URL +
          '/docs/a8_3b_otp_informativa_generale_trattamento_dei_dati_aurum.docx'
        "
      >
        Informativa al cliente per l’erogazione del servizio di firma elettronica avanzata
        tramite OTP </a
      >,
      <a
        :href="
          VUE_APP_API_URL +
          '/docs/A7_3a_-OTP-Condizioni_Generali_del_Servizio_-_Aurum.docx'
        "
      >
        Condizioni generali di servizio per l’utilizzazione della soluzione di firma
        elettronica avanzata
      </a>
    </div>


                                <v-radio-group
                                    data-cy="quotazione.radio0"
                                    v-model="quotazione.radio0"
                                    row
                                    required
                                    :rules="required"
                                >
                                    <template v-slot:label>
                                        <div>
                                            *La copertura assicurativa è rivolta
                                            alla tutela del Contraente?
                                        </div>
                                    </template>
                                    <v-radio value="ok">
                                        <template v-slot:label>
                                            <div>Si</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>No</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                <div>
                                    Informazioni sui soggetti da tutelare:
                                </div>
                                <v-select
                                    :items="[
                                        'Contraente',
                                        'Coniuge',
                                        'Familiare',
                                    ]"
                                    data-cy="quotazione.parentela"
                                    v-model="quotazione.parentela"
                                    label="Indicare il rapporto tra contraente ed assicurato"
                                    v-if="quotazione.radio0 == 'ko'"
                                ></v-select>

                                <v-radio-group
                                    data-cy="quotazione.copertura"
                                    v-model="quotazione.copertura"
                                    row
                                    required
                                    :rules="coperturaRules"
                                >
                                    <template v-slot:label>
                                        <div>
                                            Eventuali coperture assicurative in
                                            corso sullo stesso rischio?
                                        </div>
                                    </template>
                                    <v-radio value="ok">
                                        <template v-slot:label>
                                            <div>Si</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>No</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>

                                <v-radio-group
                                    data-cy="quotazione.radio4"
                                    v-model="quotazione.radio4"
                                    row
                                    required
                                    :rules="radioRules"
                                >
                                    <template v-slot:label>
                                        <div>
                                            *Desidera una copertura per la
                                            circolazione di uno o più mezzi di
                                            interesse storico, con almeno 20
                                            anni di età, che comprenda le
                                            garanzie RCA con i massimali minimi
                                            di Legge, una copertura incendio a
                                            primo rischio assoluto sui mezzi
                                            assicurati, una copertura infortuni
                                            del conducente e le garanzie
                                            Assistenza stradale e tutela legale
                                            della circolazione?
                                        </div>
                                    </template>
                                    <v-radio value="ok">
                                        <template v-slot:label>
                                            <div>Si</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>No</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                <v-radio-group
                                    data-cy="quotazione.radio5"
                                    v-model="quotazione.radio5"
                                    row
                                    required
                                    :rules="radioRules"
                                >
                                    <template v-slot:label>
                                        <div>
                                            *E' disposto ad accettare che le
                                            coperture escludano la possibilità
                                            di guida a conducenti con età
                                            inferiore ai 26 anni a meno che non
                                            venga pagato un sovrappremio?
                                        </div>
                                    </template>
                                    <v-radio value="ok">
                                        <template v-slot:label>
                                            <div>Si</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>No</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                <v-radio-group
                                    data-cy="quotazione.radio6"
                                    v-model="quotazione.radio6"
                                    row
                                    required
                                    :rules="radioRules"
                                >
                                    <template v-slot:label>
                                        <div>
                                            *E' disposto ad accettare che la
                                            copertura assicurativa sia valida
                                            solo per un mezzo circolante alla
                                            volta o, in alternativa e a fronte
                                            del pagamento di un sovrappremio,
                                            per la circolazione di più mezzi
                                            circolanti contemporaneamente?
                                        </div>
                                    </template>
                                    <v-radio value="ok">
                                        <template v-slot:label>
                                            <div>Si</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>No</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                <v-radio-group
                                    data-cy="quotazione.radio7"
                                    v-model="quotazione.radio7"
                                    row
                                    required
                                    :rules="radioRules"
                                >
                                    <template v-slot:label>
                                        <div>
                                            Il mezzo che intende assicurare è
                                            iscritto e tale iscrizione è in
                                            vigore, ad un Club dedicato a
                                            veicoli storici? In caso contrario
                                            non sarà possibile aderire
                                            all'offerta assicurativa proposta.
                                        </div>
                                    </template>
                                    <v-radio value="ok">
                                        <template v-slot:label>
                                            <div>Si</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>No</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                <v-radio-group
                                    data-cy="quotazione.radio8"
                                    v-model="quotazione.radio8"
                                    row
                                    required
                                    :rules="radioRules"
                                >
                                    <template v-slot:label>
                                        <div>
                                            E' informato e consapevole che la
                                            mancata validità dell'iscrizione al
                                            Club farebbe decadere la validità
                                            della copertura assicurativa
                                            proposta o in caso di sinistro la
                                            compagnia potrebbe esercitare
                                            diritto di rivalsa?
                                        </div>
                                    </template>
                                    <v-radio value="ok">
                                        <template v-slot:label>
                                            <div>Si</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>No</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                <v-radio-group
                                    data-cy="quotazione.radio9"
                                    v-model="quotazione.radio9"
                                    row
                                    required
                                    :rules="radioRules"
                                >
                                    <template v-slot:label>
                                        <div>
                                            E' informato e consapevole del fatto
                                            che la copertura assicurativa
                                            proposta esclude l'utilizzo del
                                            mezzo ai fini professionali ?
                                        </div>
                                    </template>
                                    <v-radio value="ok">
                                        <template v-slot:label>
                                            <div>Si</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>No</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                <v-radio-group
                                    data-cy="quotazione.radio10"
                                    v-model="quotazione.radio10"
                                    row
                                    required
                                    :rules="radioRules"
                                >
                                    <template v-slot:label>
                                        <div>
                                            E' informato e consapevole del fatto
                                            che potrà aderire alla copertura
                                            assicurativa proposta solo in
                                            presenza di un attestato di rischio
                                            (SIC) senza sinistri nell'ultimo
                                            quinquennio?
                                        </div>
                                    </template>
                                    <v-radio value="ok">
                                        <template v-slot:label>
                                            <div>Si</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>No</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                <v-radio-group
                                    data-cy="quotazione.radio11"
                                    v-model="quotazione.radio11"
                                    row
                                    required
                                    :rules="radioRules"
                                >
                                    <template v-slot:label>
                                        <div>
                                            E' informato del fatto che potrà
                                            aderire alla copertura assicurativa
                                            facoltativa contro il furto per un
                                            valore assicurato a primo rischio
                                            assoluto di € 8.000,00?
                                        </div>
                                    </template>
                                    <v-radio value="ok">
                                        <template v-slot:label>
                                            <div>Si</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>No</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                <v-radio-group
                                    data-cy="quotazione.radio12"
                                    v-model="quotazione.radio12"
                                    row
                                    required
                                    :rules="radioRules"
                                >
                                    <template v-slot:label>
                                        <div>
                                            E' informato del fatto che potrai
                                            estendere le coperture RCA, Tutela
                                            Legale e assistenza all'utilizzo
                                            contestuale di più veicoli da parte
                                            di componenti del nucleo famigliare,
                                            pagando il sovrappremio previsto?
                                        </div>
                                    </template>
                                    <v-radio value="ok">
                                        <template v-slot:label>
                                            <div>Si</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>No</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                <v-radio-group
                                    data-cy="quotazione.radio13"
                                    v-model="quotazione.radio13"
                                    row
                                    required
                                    :rules="radioRules"
                                >
                                    <template v-slot:label>
                                        <div>
                                            E' informato del fatto che potrà
                                            eliminare o inserire nuovi veicoli
                                            nel corso della durata contrattuale,
                                            a fronte di una integrazione
                                            economica?
                                        </div>
                                    </template>
                                    <v-radio value="ok">
                                        <template v-slot:label>
                                            <div>Si</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>No</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                <v-radio-group
                                    data-cy="quotazione.radio14"
                                    v-model="quotazione.radio14"
                                    row
                                    required
                                    :rules="radioRules"
                                >
                                    <template v-slot:label>
                                        <div>
                                            Le caratteristiche della proposta
                                            assicurativa e i suoi contenuti
                                            rispecchiano le Sue esigenze e
                                            aspettative?
                                        </div>
                                    </template>
                                    <v-radio value="ok">
                                        <template v-slot:label>
                                            <div>Si</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>No</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                <v-radio-group
                                    data-cy="quotazione.radio15"
                                    v-model="quotazione.radio15"
                                    row
                                    required
                                    :rules="radioRules"
                                >
                                    <template v-slot:label>
                                        <div>
                                            E' Consapevole delle esclusioni,
                                            limitazioni, franchigie e scoperti
                                            contenute nella proposta
                                            assicurativa prospettata?
                                        </div>
                                    </template>
                                    <v-radio value="ok">
                                        <template v-slot:label>
                                            <div>Si</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>No</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                                <v-radio-group
                                    data-cy="quotazione.radio16"
                                    v-model="quotazione.radio16"
                                    row
                                    required
                                    :rules="radioRules"
                                >
                                    <template v-slot:label>
                                        <div>
                                            Le sono chiare la durata del
                                            contratto, le modalità di rinnovo ,
                                            le facoltà di recesso e le facoltà
                                            di rivalsa dell' assicuratore?
                                        </div>
                                    </template>
                                    <v-radio value="ok">
                                        <template v-slot:label>
                                            <div>Si</div>
                                        </template>
                                    </v-radio>
                                    <v-radio value="ko">
                                        <template v-slot:label>
                                            <div>No</div>
                                        </template>
                                    </v-radio>
                                </v-radio-group>
                            </v-form>
                        </v-card-text>
                    </v-card>
                    <v-btn color="primary" @click="goToStep(2)">
                        Indietro
                    </v-btn>
                    <v-btn text> Annulla </v-btn>
                    <v-btn color="primary" @click="goToStep(4)"> Avanti </v-btn>
                </v-stepper-content>
                <v-stepper-content step="4">
                    <v-card class="mb-12" color="">
                        <v-card-title>Veicoli storici</v-card-title>
                        <v-form ref="form4" lazy-validation>
                            <v-simple-table class="mb-5">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">Marca</th>
                                            <th class="text-left">Modello</th>
                                            <th class="text-left">
                                                Anno immatricolazione
                                            </th>
                                            <th class="text-left">Furto</th>
                                            <th class="text-left">Targa</th>
                                            <th class="text-left">Tipologia</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="item in quotazione.veicoli_storici"
                                            :key="item.id"
                                        >
                                            <td data-cy="td-item.marca">
                                                {{ item.marca }}
                                            </td>
                                            <td data-cy="td-item.modello">
                                                {{ item.modello }}
                                            </td>
                                            <td data-cy="td-item.anno">
                                                {{ item.anno }}
                                            </td>
                                            <td data-cy="td-item.furto">
                                                {{ item.furto }}
                                            </td>
                                            <td data-cy="td-item.targa">
                                                <v-text-field
                                                    :data-cy="
                                                        'item.targa' +
                                                        item.marca
                                                    "
                                                    v-model.trim="item.targa"
                                                    label="Immetti la targa"
                                                    :rules="required"
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-select
                                                    label="Tipologia"
                                                    :items="tipologie_ania"
                                                    item-text="nome"
                                                    item-value="codice"
                                                    :data-cy="
                                                        'item.tipologia_ania' +
                                                        item.marca
                                                    "
                                                    v-model="
                                                        item.tipologia_ania
                                                    "
                                                    :rules="required"
                                                >
                                                </v-select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-card-title>Veicoli moderni</v-card-title>
                            <v-row class="mb-3">
                                <v-col>
                                    <div class="form-group">
                                        <v-select
                                            autocomplete="new-password"
                                            :items="[1, 2, 3, 4, 5, 6]"
                                            label="Patenti nucleo famigliare:"
                                            data-cy="quotazione.patenti"
                                            v-model="quotazione.patenti"
                                            @change="calcoloQuotidiano"
                                        ></v-select>
                                    </div>
                                </v-col>
                                <v-col>
                                    <div
                                        class="form-group"
                                        v-if="quotazione.patenti"
                                    >
                                        <v-select
                                            autocomplete="new-password"
                                            :items="numeroVeicoli"
                                            label="Veicoli uso quotidiano:"
                                            data-cy="quotazione.quotidiano"
                                            v-model="quotazione.quotidiano"
                                            @change="aggiungiVeicoli"
                                        >
                                        </v-select>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-simple-table
                                class="mb-5"
                                v-if="quotazione.patenti"
                            >
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">Marca</th>
                                            <th class="text-left">Modello</th>
                                            <th class="text-left">
                                                Anno immatricolazione
                                            </th>

                                            <th class="text-left">Targa</th>
                                            <th class="text-left">
                                                Grado di parentela
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="veicolo in veicoli_moderni"
                                            :key="veicolo.id"
                                        >
                                            <td data-cy="td-item.marca">
                                                <v-text-field
                                                    v-model="veicolo.marca"
                                                    label="Immetti la Marca"
                                                    :rules="required"
                                                ></v-text-field>
                                            </td>
                                            <td data-cy="td-item.modello">
                                                <v-text-field
                                                    v-model="veicolo.modello"
                                                    label="Immetti il modello"
                                                    :rules="required"
                                                ></v-text-field>
                                            </td>
                                            <td data-cy="td-item.anno">
                                                <v-text-field
                                                    v-model="veicolo.anno"
                                                    label="Immetti l'anno"
                                                    :rules="required"
                                                ></v-text-field>
                                            </td>
                                            <td data-cy="td-item.targa">
                                                <v-text-field
                                                    v-model.trim="veicolo.targa"
                                                    label="Immetti la targa"
                                                    :rules="required"
                                                ></v-text-field>
                                            </td>
                                            <td>
                                                <v-select
                                                    :items="[
                                                        'Contraente',
                                                        'Coniuge',
                                                        'Familiare',
                                                    ]"
                                                    v-model="
                                                        veicolo.grado_parentela
                                                    "
                                                    label="Grado di parentela"
                                                ></v-select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-form>
                    </v-card>
                    <v-btn color="primary" @click="goToStep(2)">
                        Indietro
                    </v-btn>
                    <v-btn text> Annulla </v-btn>
                    <v-btn color="primary" @click="inviaDati"> Fine </v-btn>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <v-card class="mx-auto" v-if="datiInviati">
            <v-card-title>Procedimento completato con successo</v-card-title>

            <v-card-subtitle class="pb-0"> Grazie </v-card-subtitle>

            <v-card-text class="text--primary">
                <div>
                    Riceverai una mail riepilogativa con le indicazioni per il
                    proseguimento della pratica.
                </div>
            </v-card-text>
        </v-card>
        <v-card class="mx-auto" v-if="error">
            <v-card-title>Errore</v-card-title>
            <v-card-subtitle class="pb-0"> Attenzione, </v-card-subtitle>
            <v-card-text class="text--primary">
                <div>{{ this.errorMessage }}</div>
            </v-card-text>
        </v-card>

        <upload-form
            :quotazione="quotazione"
            v-if="quotazione.stato_quotazione_id == 5 && !datiInviati"
        ></upload-form>

        <div
            :class="{ 'loading-overlay': loading }"
            style="z-index: 10000"
        ></div>
    </div>
</template>

<script>
import AutocompleteVue from "../ui/Autocomplete.vue";
import { errorCallback } from "@/http_tools/axiosModule";

import { EnvMixin } from "@/mixin/EnvMixin";
import axios from "axios";
//import DropzoneUpload from '../ui/form/dropzone-upload.vue';
import UploadForm from "../quotazione/UploadForm.vue";
export default {
    mixins: [EnvMixin],
    data() {
        return {
            dialog: false,
            quotazione: {},
            quotazione_id: null,
            errorMessage: null,
            nome: null,
            cognome: null,
            email: null,
            cfisc: null,
            telefono: null,
            provincia_codice: null,
            checkbox1: false,
            veicoli_moderni: [],
            veicolo_moderno: [],
            anno: null,
            marca: null,
            modello: null,
            grado_parentela: null,
            targa: null,
            e1: 0,
            error: false,
            datiInviati: false,
            otp: null,
            form1Valid: false,
            mode: null,
            loading: false,
            activePicker: null,
            menu: false,
            parameter1: null,
            headers: [
                { text: "Marca", value: "marca" },
                { text: "Modello", value: "modello" },
                { text: "Anno immatricolazione", value: "anno" },
                { text: "Targa", value: "targa" },
                { text: "Grado di parentela", value: "grado_parentela" },
                { text: "Actions", value: "actions", sortable: false },
            ],
            tipologie_ania: [
                { codice: "A", nome: "Autovettura" },
                { codice: "B", nome: "Autobus" },
                { codice: "C", nome: "Autocarro" },
                { codice: "M", nome: "Motocicli" },
                { codice: "Q", nome: "Motocarri" },
                { codice: "R", nome: "Rimorchi" },
                { codice: "S", nome: "Macchine Agricole" },
                { codice: "T", nome: "Macchine Operatrici" },
                { codice: "W", nome: "Ciclomotori" },
                { codice: "Z", nome: "Quadriciclo" },
            ],

            uuid: null,
            ruleTelefono: [
                (value) => !!value || "Campo Obbligatorio.",
                (value) =>
                    /^\+39\d{4,12}$/.test(value) ||
                    "Formato telefono non valido",
            ],

            requiredF: [
                (value) =>
                    this.quotazione.persona == "g" ||
                    !!value ||
                    "Campo Obbligatorio.",
            ],
            requiredG: [
                (value) =>
                    this.quotazione.persona == "f" ||
                    !!value ||
                    "Campo Obbligatorio.",
            ],
            required: [
                function (value) {
                    return !!value || "Campo Obbligatorio.";
                },
            ],
            radioRules: [
                (value) => value == "ok" || "Obbligatorio prestare il consenso",
            ],
            coperturaRules: [
                (value) =>
                    value == "ko" ||
                    "Non puoi avere altre coperture assicurative in corso sullo stesso rischio",
            ],
            numeric: [
                (v) =>
                    Number.isInteger(Number(v)) || "Inserisci un anno valido",
                (v) => v < 2050 || "Inserisci un anno valido",
            ],
            ruleTarga: [
                (value) => !!value || "Campo Obbligatorio.",
                (value) =>
                    /^[a-zA-Z]{2}[0-9]{3,4}[a-zA-Z]{2}$/gm.test(value) ||
                    "Targa non valida",
            ],
            cfiscRules: [
                (value) =>
                    /^[a-zA-Z]{6}[0-9]{2}[abcdehlmprstABCDEHLMPRST]{1}[0-9]{2}([a-zA-Z]{1}[0-9]{3})[a-zA-Z]{1}$/gm.test(
                        value,
                    ) || "Codice fiscale invalido",
            ],
        };
    },
    components: {
        "teo-autocomplete": AutocompleteVue,
        UploadForm,
    },
    computed: {
        attivita_lavorativa_items() {
            if (this.quotazione.persona == "f") {
                return [
                    "Dipendente a tempo indeterminato",
                    "Dipendente a tempo determinato",
                    "Lavoro Autonomo",
                    "Non Occupato",
                ];
            } else {
                return [
                    "Rappresentante di ditta individuale",
                    "Rappresentante di società per azioni (S.P.A.)",
                    "Rappresentante di società a responsabilità limitata (S.R.L.)",
                    "Altro",
                ];
            }
        },
        resSede() {
            if (this.quotazione.persona == "f") return "Residenza";
            else return "Sede Legale";
        },
    },
    created: function () {
        this.uuid = this.$route.params.uuid;
        this.fetchQuotazione();
        this.parameter1 = this.$route.query.parameter1;
    },
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = "YEAR"));
        },
    },
    methods: {
        calcoloQuotidiano() {
            if (this.quotazione.patenti < 4) {
                this.numeroVeicoli = [1, 2, 3, 4, 5, 6];
            }
            if (this.quotazione.patenti == 4) {
                this.numeroVeicoli = [2, 3, 4, 5, 6];
            }
            if (this.quotazione.patenti == 5) {
                this.numeroVeicoli = [2, 3, 4, 5, 6];
            }
            if (this.quotazione.patenti == 6) {
                this.numeroVeicoli = [3, 4, 5, 6];
            }
            this.aggiungiVeicoli();
        },
        aggiungiVeicoli() {
            this.veicoli_moderni = [];
            for (let i = 0; i < this.quotazione.quotidiano; i++) {
                let veicolo = {
                    id: "",
                    marca: "",
                    modello: "",
                    anno: "",
                    targa: "",
                    grado_parentela: "",
                };
                this.veicoli_moderni.push(veicolo);
            }
        },
        saveDate(date) {
            this.$refs.menu.save(date);
        },
        checkFile(collection) {
            this.quotazione.media.forEach((element) => {
                if (element["collection_name"] == collection) {
                    return true;
                }
            });
            return false;
        },

        documentUrl(collection, id) {
            return (
                process.env.VUE_APP_API_URL +
                "/api/quotazioni/getDocument/" +
                collection +
                "/" +
                id
            );
        },
        async salvaClienteAndGoToStep() {
            if (this.$refs.form1.validate()) {
                //this.loading = true;
                if (this.quotazione.persona == "g") this.quotazione.sesso = "A";
                await axios
                    .post(
                        process.env.VUE_APP_API_URL + "/api/cliente/quotazione",
                        this.quotazione,
                    )
                    .then((response) => {
                        this.response = response;
                        if (this.response.status == 201) {
                            this.quotazione.cliente_id =
                                response.data.data.model.id;

                            this.goToStep(2);
                            //this.loading = false;
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            errorCallback(error);
                            this.errors = error.response.data.errors;
                        }
                    });
            }
        },
        goToStep(n) {
            switch (n) {
                case 1:
                    this.e1 = n;
                    break;
                case 2:
                    if (this.$refs.form1.validate()) {
                        this.e1 = n;
                    }
                    break;
                case 3:
                    if (this.$refs.form2.validate()) {
                        this.e1 = n;
                    }
                    break;
                case 4:
                    if (this.$refs.form3.validate()) {
                        this.e1 = n;
                    }
                    break;
                default:
                    break;
            }
        },
        async fetchQuotazione() {
            await axios
                .get(
                    process.env.VUE_APP_API_URL +
                        "/api/cliente/quotazione/" +
                        this.uuid,
                )
                .then((response) => {
                    this.quotazione = response.data.data;
                    this.aggiungiVeicoli();
                    if (this.quotazione.stato_quotazione_id == 4) {
                        this.mode = "form";
                    }
                    if (this.quotazione.stato_quotazione_id == 6) {
                        this.mode = "upload";
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.errorMessage = error.response.data.message;
                        this.error = true;
                    }
                });
        },
        aggiungiVeicoloModerno() {
            this.veicolo_moderno.marca = this.marca;
            this.veicolo_moderno.modello = this.modello;
            this.veicolo_moderno.anno = this.anno;
            this.veicolo_moderno.targa = this.targa;
            this.veicolo_moderno.grado_parentela = this.grado_parentela;
            const veicolo_moderno = { ...this.veicolo_moderno };
            this.veicoli_moderni.push(veicolo_moderno);
            this.dialog = false;
        },

        deleteItem(item) {
            this.editedIndex = this.veicoli_moderni.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.veicoli_moderni.splice(this.editedIndex, 1);
        },
        closeDialog() {
            this.selectedMarca = null;
            this.selectedItem = null;
            this.dialog = false;
            //this.loading = false;
        },

        async inviaDati() {
            let controllo = true;
            this.quotazione.veicoli_storici.forEach((v) => {
                if (!v.tipologia_ania) {
                    alert(
                        "non hai compilato la tipologia di veicolo per la targa " +
                            v.targa,
                    );
                    controllo = false;
                }
            });

            if (controllo && this.$refs.form4.validate()) {
                this.loading = true;
                this.quotazione.veicoli_moderni = this.veicoli_moderni;
                console.log(this.quotazione);

                await axios
                    .put(
                        process.env.VUE_APP_API_URL +
                            "/api/cliente/quotazione/" +
                            this.quotazione.id,
                        this.quotazione,
                    )
                    .then((response) => {
                        this.response = response;
                        if (this.response.status == 201) {
                            this.response.type = "success";
                            this.datiInviati = true;
                            this.loading = false;
                        } else {
                            this.errorMessage = this.response.data.message;
                            this.error = true;
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            this.errorMessage = error.response.data.message;
                            this.error = true;
                            this.loading = false;
                        }
                    });
            }
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped></style>
<style>
.dropzone {
    height: 100% !important;
}
</style>
