<template>
    <div>
        <v-card>

          <img src="autoretro.png" alt="Logo">

          
            <v-card-title>
                <v-toolbar flat color="transparent">
                    <v-toolbar-title
                        >Documenti Per Autostoriche GOOld</v-toolbar-title
                    >
                </v-toolbar>
            </v-card-title>
            <v-card-text>
                <v-list>
                    <v-list-item v-for="(item, index) in items" :key="index">
                        <v-list-item-avatar>
                            <v-icon>mdi-file-pdf-box</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                <a :href="item.link" download target="_blank">{{
                                    item.name
                                }}</a>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card-text>

            <v-card-text>
    <v-card-title>Precontrattuale</v-card-title>
    <div>
      *Il cliente dichiara di aver consultato e preso visione dei moduli:
      <a target="_blank" :href="VUE_APP_API_URL + '/docs/1 -Allegato 3  4 - 4ter STORICHE 4-23.pdf'" >Allegato 3/4/4 Ter.</a>,
      <!-- <a :href="VUE_APP_API_URL + '/docs/allegato_4.pdf'">Allegato 4</a>,
      <a :href="VUE_APP_API_URL + '/docs/allegato_4_ter.pdf'">Allegato 4 TER</a>, -->
      <a
        :href="
          VUE_APP_API_URL + '/docs/informativa_precontrattuale_contratti_distanza.pdf'
        "
      >
        Informativa precontrattuale relative ai contratti stipulati a distanza </a
      >,
      <a :href="VUE_APP_API_URL + '/docs/lettera_incarico_broker.pdf'"
        >Lettera incarico Broker</a
      >,
      <a
        :href="VUE_APP_API_URL + '/docs/set_informativo_verti_veicoli_storici.pdf'"
        @click.stop
        target="_blank"
      >
        Set informativo VERTI Assicurazioni SpA </a
      >,
      <a
        :href="VUE_APP_API_URL + '/docs/set_informativo_am_trust_privacy.pdf'"
        @click.stop
        target="_blank"
      >
        Set informativo AmTrust Assicurazioni SpA </a
      >,
      <a
        :href="VUE_APP_API_URL + '/docs/informativa_infodrive_spa.pdf'"
        @click.stop
        target="_blank"
      >
        Informativa Infodrive SpA
      </a>
    </div>
</v-card-text>

<v-card-text>
    <v-card-title>Modalità di invio documentazione</v-card-title>

    <div>
      La formula assicurativa proposta prevede l’invio della documentazione in modalità
      telematica, quindi senza invio di tutta la documentazione in modalità cartacea. Il
      cliente dichiara di aver consultato e preso visione del modulo:
      <a
        :href="
          VUE_APP_API_URL +
          '/docs/a8_3b_otp_informativa_generale_trattamento_dei_dati_aurum.docx'
        "
      >
        Informativa al cliente per l’erogazione del servizio di firma elettronica avanzata
        tramite OTP </a
      >,
      <a
        :href="
          VUE_APP_API_URL +
          '/docs/A7_3a_-OTP-Condizioni_Generali_del_Servizio_-_Aurum.docx'
        "
      >
        Condizioni generali di servizio per l’utilizzazione della soluzione di firma
        elettronica avanzata
      </a>
    </div>
</v-card-text>
        </v-card>

        <!-- <a :href="VUE_APP_API_URL+'/docs/set_informativo_verti_veicoli_storici.pdf'" @click.stop
                                                target="_blank">Set
                                                informativo VERTI Assicurazioni SpA</a>,
                                            <a :href="VUE_APP_API_URL+'/docs/set_informativo_am_trust_privacy.pdf'" @click.stop
                                                target="_blank">Set informativo AmTrust Assicurazioni SpA</a>,
                                            <a :href="VUE_APP_API_URL+'/docs/informativa_infodrive_spa.pdf'" @click.stop
                                                target="_blank">Informativa Infodrive
                                                SpA</a>                                        -->
    </div>
</template>

<script>
import { EnvMixin } from "@/mixin/EnvMixin";
export default {
    mixins: [EnvMixin],
    data: () => ({
        drawer: null,
        items: [
            {
                name: "Set Informativo AM Trust Privacy",
                link:
                    process.env.VUE_APP_API_URL +
                    "/docs/set_informativo_am_trust_privacy.pdf",
            },
            {
                name: "Set Informativo Verti Veicoli Storici",
                link:
                    process.env.VUE_APP_API_URL +
                    "/docs/set_informativo_verti_veicoli_storici.pdf",
            },
            {
                name: "Set Informativa infodrive spa",
                link:
                    process.env.VUE_APP_API_URL +
                    "/docs/informativa_infodrive_spa.pdf",
            },
            {
                name: "Privacy Aurum dal Febbraio 2023",
                link:
                    process.env.VUE_APP_API_URL +
                    "/docs/privacy-AURUM-dal-2-2023.pdf",
            },
        ],
    }),
    components: {},
    mounted() {
        this.$store.state.compagnie;
    },
    computed: {
        cards() {
            return this.$store.state.dashboard.cards;
        },
        breadcrumbs() {
            return this.$store.state.breadcrumbs;
        },
    },
};
</script>
