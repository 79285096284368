<template>
  <div data-test="ContactForm">
    <h6 class="mb-4">Contatti</h6>
    <v-form-base v-if="show" :schema="schema" :model="model"> </v-form-base>
    {{ type }}
  </div>
</template>

<script>
import VFormBase from "vuetify-form-base";

export default {
  name: "contact-form",
  props: ["type", "value", "obj", "errors"],
  components: {
    VFormBase,
  },
  created() {},
  async mounted() {
    this.schema = await ContactFormSchema(this.errors);
    this.show = true;
  },
  data() {
    return {
      show: true,
      schema: {},
    };
  },
  methods: {
    // add() { },
    // remove()
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};

async function ContactFormSchema(errors = {}) {
  errors;
  const formSchema = {
    isPrincipal: {
      hidden: true,
      type: "switch",
      label: "usare",
      trueValue: true,
      falseValue: false,
      col: 4,
    },
    // type: {
    //   // type: "select",
    //   type: "select",
    //   hide: true,
    //   readonly: true,
    //   label: "Tipo di Contatto:",
    //   items: ["Mobile", "Email", "Telefono Fisso"], // Puoi aggiungere più tipi
    //   // rules: ['required'],
    //   col: 6,
    //   class:"d-none"
    // },
    value_contact: {
      type: "text",
      label: "Email",
      // rules: ['required'],
      col: 6,

      // rules: ['required'],
      ext: "autocomplete=new-password onblur='this.focus()'",
      persistentHint: true,
      retainFocusOnInput: true,
      validateOnBlur: false,
      lazyValidation: true,
    },
  };

  return formSchema;
}
</script>

<style></style>
