<template>
  <div data-test="FastQuotationMainForm">
    <section v-if="model">
      <v-alert dense text type="error" v-if="Object.keys(messageBag).length">
        {{ messageBag.message }}
        <v-btn small color="error" @click="dialog = true">Dettagli</v-btn>
      </v-alert>
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>Errori</v-card-title>
          <v-card-text>
            <pre :content-editable="true" style="white-space: pre-wrap">{{
              messageBag.errors
            }}</pre>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="dialog = false">Chiudi</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- <pre>cliente_id: {{ this.model?.cliente_id }}</pre> -->

      <form ref="quotationForm" class="quotation-form">
        <!-- Aggiungi l'overlay di loading -->
        <v-overlay :value="loading">
          <v-progress-circular
            indeterminate
            size="64"
            color="primary"
          ></v-progress-circular>
        </v-overlay>

        <p>
          <b>Promozioni:</b> Non tutte le quotazioni attivano i codici sconto delle
          promozioni. Se la tariffa non è applicabile verrà visualizzato <b>"Quotazione non
          disponibile"</b>
        </p>
        <v-toolbar flat>
          <div class="d-flex justify-between">
            <div>
              <v-btn
                @click="prevStep"
                v-bind="btn_primary_style"
                color="primary"
                class="mr-3"
                >Indietro</v-btn
              >
              <v-btn
                @click="nextStep"
                v-bind="btn_primary_style"
                color="primary"
                class="mr-3"
                >Continua</v-btn
              >
              <!-- <v-btn
      @click="salvaBozza"
      :disabled="abilitaSalvataggabilitaSalvataggioBozzaFastioBozzaFast"
      v-bind="btn_primary_style"
      color="primary"
       class="mr-3"
      >Salva Bozza</v-btn
    > -->
            </div>
            <div class="d-flex">
              <v-btn
                v-if="currentStep > 2 && isEmpty(this.model?.quotation_response)"
                @click="richiediPreventivoFastQuotationStepperForm"
                color="primary"
                v-bind="btn_primary_style"
                class="mr-3"
              >
                Richiedi Preventivo
              </v-btn>
              <v-btn
                v-if="!isEmpty(this.model?.quotation_response)"
                @click="richiediPreventivoFastQuotationStepperForm"
                color="primary"
                v-bind="btn_primary_style"
                class="mr-3"
              >
                Aggiorna Preventivo
              </v-btn>

              <!-- :rules="[rules.required]" -->
              <v-select
                v-if="model.quotation_request"
                dense
                v-model="model.quotation_request.fastFlowData.convention"
                :items="[
                  { text: 'QP_STANDARD', value: 'QP_STANDARD' },
                  { text: 'QP_PROMO 5', value: 'QP_PROMO_5' },
                  { text: 'QP_PROMO 10', value: 'QP_PROMO_10' },
                  { text: 'QP_PROMO 15', value: 'QP_PROMO_15' },
                  { text: 'QP_PROMO 20', value: 'QP_PROMO_20' },
                ]"
                label="Seleziona una promozione"
                required
                class="mr-3 mt-2"
              />
            </div>
          </div>
        </v-toolbar>

        <!-- :loading="resetting" -->
        <v-stepper v-model="currentStep">
          <v-stepper-header>
            <!-- ------------------------------------------
                     1. HEADER STEP: SELEZIONE CLIENTE
                     ------------------------------------------ -->
            <v-stepper-step
              :complete="currentStep > 1"
              step="1"
              :rules="[() => isClienteSelezionatoStep]"
              @click="goToStep(1)"
              :editable="currentStep > 1"
            >
              Seleziona Cliente
              <small>Seleziona o crea un nuovo cliente</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <!-- ------------------------------------------
                     2. HEADER STEP: QUOTAZIONE VELOCE
                     ------------------------------------------ -->
            <v-stepper-step
              :complete="currentStep > 2"
              step="2"
              @click="goToStep(2)"
              :editable="currentStep > 2"
            >
              Quotazione Veloce
              <small>Inserisci i dati base</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <!-- ------------------------------------------
                     3. HEADER STEP: GARANZIE
                     ------------------------------------------ -->
            <v-stepper-step
              :complete="currentStep > 3"
              step="3"
              @click="goToStep(3)"
              :editable="currentStep > 3"
            >
              Garanzie
              <small>Scegli le garanzie</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <!-- ------------------------------------------
                     4. HEADER STEP: CONFERMA
                     ------------------------------------------ -->
            <v-stepper-step step="4" @click="goToStep(4)" :editable="currentStep > 4">
              Conferma
              <small>Verifica e conferma</small>
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-- ------------------------------------------
                     1. ITEM STEP: SELEZIONE CLIENTE
                     ------------------------------------------ -->
            <v-stepper-content step="1">
              <v-card class="mb-12" elevation="0">
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-btn-toggle
                        v-model="tipoCliente"
                        @change="onTipoClienteChanged"
                        mandatory
                      >
                        <v-btn value="esistente"> Cliente Esistente </v-btn>
                        <v-btn value="nuovo"> Nuovo Cliente </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>

                  <!-- v-model:veicolo_id="model.veicolo_id" -->
                  <ClienteSearchForm
                    @on-search-result-selected="onStep_1_ClienteEsistenteSelezionato"
                  />
                </v-card-text>
              </v-card>

              <v-btn
                color="primary"
                @click="nextStep"
                :disabled="!isClienteSelezionatoStep"
              >
                Continua
              </v-btn>
            </v-stepper-content>

            <!-- ------------------------------------------
                     2. ITEM STEP: QUOTAZIONE VELOCE
                     ------------------------------------------ -->
            <v-stepper-content step="2">
              <FastQuotationForm
                v-model="model.quotation_request"
                :errors="messageBag?.errors"
              />
            </v-stepper-content>

            <!-- ------------------------------------------
                             3. ITEM STEP: GARANZIE
                        ------------------------------------------ -->
            <v-stepper-content step="3">
              <div v-if="model.quotation_request">
                <h6 class="header-form-tab">Garanzie</h6>
                <v-card class="mb-12" elevation="0">
                  <div
                    v-if="model.quotation_response && model.quotation_response.simulation"
                  >
                    <QuotationStatus
                      :simulation="model.quotation_response.simulation"
                      ref="quotationStatus"
                    ></QuotationStatus>
                  </div>

                  <CoverageQuixaForm
                    v-if="model?.quotation_request?.coverages"
                    v-model="model.quotation_request.coverages"
                    @click="richiediPreventivoFastQuotationStepperForm"
                    :vehicle-category="
                      model.quotation_request.fastFlowData.vehicleCategory
                    "
                  />
                </v-card>
              </div>
            </v-stepper-content>

            <!-- ------------------------------------------
                     4. ITEM STEP: CONFERMA
                     ------------------------------------------ -->
            <v-stepper-content step="4">
              <div v-if="model.quotation_response && model.quotation_response.simulation">
                <h6 class="header-form-tab">
                  Riepilogo e Conferma <br />N°
                  {{ model.quotation_response.simulation.commonData.simulationNumber }}
                </h6>

                <v-btn
                  color="success"
                  @click="
                    trasformaQuotationInProposal(
                      model.quotation_response.simulation.commonData.simulationNumber
                    )
                  "
                >
                  Conferma Quotazione
                </v-btn>
                <v-card class="mb-12" elevation="0">
                  <v-card-text v-if="false && model?.quotation_response">
                    <LayoutFromJson v-model="model.quotation_response"></LayoutFromJson>
                    <pre>{{ model.quotation_response }}</pre>
                    <!-- Qui inserire il componente di riepilogo -->
                  </v-card-text>
                </v-card>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <pre>
            <b>FastQuotation Stepper</b>
            model: {{ model }}
            </pre>
      </form>
    </section>
  </div>
</template>

<script>
import { StylesMixin } from "@/style/StyleMixing";
import ClienteSearchForm from "../cliente/ClienteSearchForm.vue";
// import MainSelectionForm from "@/v-form-base/quixa/form/MainSelectionForm.vue";
import FastQuotationForm from "@/v-form-base/quixa/form/FastQuotationForm.vue";
import CoverageQuixaForm from "@/v-form-base/quixa/form/CoverageQuixaForm.vue";
import { Api, errorCallback } from "@/http_tools/axiosModule";
import {
  castToBoolean,
  JsonToolsMixing,
  replaceValueContact,
  transformErrors,
} from "@/services/JSONTools";
import { FastQuotationFlowDefaultModel } from "@/v-form-base/form-common";
import _ from "lodash";
import QuotationStatus from "@/v-form-base/quixa/QuotationStatus.vue";
import LayoutFromJson from "./LayoutFromJson.vue";

export default {
  name: "FastQuotationStepperForm",
  mixins: [StylesMixin, JsonToolsMixing],
  props: [
    "type",
    "value",
    // "errors"
  ],

  components: {
    ClienteSearchForm,
    // MainSelectionForm,
    FastQuotationForm,
    CoverageQuixaForm,
    QuotationStatus,
    LayoutFromJson,
  },

  data() {
    return {
      messageBag: {
        // errors: [],
        // message: "",
      },
      currentStep: 1,
      loading: false,
      isClienteSelezionatoStep: false,
      tipoCliente: "esistente",
      errors: {},
      quotationId: null,
      dialog: false,
    };
  },

  methods: {
    /**
     * Crea una nuova quotazione o aggiorna una esistente
     */
    async richiediPreventivoFastQuotationStepperForm() {
      this.loading = true;
      this.messageBag = {};

      try {
        // Costruiamo l'URL con il cliente_id come querystring
        const baseUrl = `${process.env.VUE_APP_API_URL}/api/quixa/FastQuotationFlow`;
        const url = this.model?.cliente_id
          ? `${baseUrl}?cliente_id=${this.model?.cliente_id}`
          : baseUrl;

        const _response = await Api.post(url, this.requestHTTP);

        console.log(
          "richiediPreventivoFastQuotationStepperForm success",
          _response.data.data
        );
        const responseData = _response.data.data;
        this.model.quotation_response = responseData.quotation_response;
        this.$refs.quotationStatus.$forceUpdate(); //
      } catch (err) {
        console.log("richiediPreventivoFastQuotationStepperForm ERROR", err);
        if (err?.response?.data) {
          console.log("richiediPreventivoFastQuotationStepperForm ERROR", err.status);
          this.messageBag.message = err.response.data.message;
          this.messageBag.errors = transformErrors(err.response.data.errors);

          this.$forceUpdate();
          console.log(this.messageBag.errors);
        } else {
          console.log("richiediPreventivoFastQuotationStepperForm 500 !!! ", err);
          this.messageBag = errorCallback(err);

          //console.log(err)
        }
      }
      this.loading = false;
    },

    /** BOTTONI DI NAVIGAZIONE */
    async goToStep(step) {
      if (step == 1) {
        // await this.updateQuotation();
        this.tipoCliente = "esistente";
      }
      this.currentStep = step;
    },
    async nextStep() {
      if (this.currentStep < 4) {
        // await this.updateQuotation();
        this.currentStep += 1;
      }
    },

    async prevStep() {
      if (this.currentStep > 1) {
        // await this.updateQuotation();
        this.currentStep -= 1;
      }
    },

    async onTipoClienteChanged(tipoDiCliente) {
      console.log("onTipoClienteChanged", tipoDiCliente);

      // ------------------------------------------
      // GESTIONE NUOVO CLIENTE
      // Quando l'utente seleziona "Nuovo Cliente":
      // 1. Resetta il model con i valori di default di FastQuotation
      // 2. Torna al primo step
      // 3. Abilita il passaggio allo step successivo
      // 4. Avanza automaticamente allo step 2
      // ------------------------------------------

      // this.tipoDiCliente = tipoDiCliente;
      if (tipoDiCliente === "nuovo") {
        this.model.quotation_request = await FastQuotationFlowDefaultModel();
        this.currentStep = 2;
        this.isClienteSelezionatoStep = true;
        this.model.cliente_id = null;
        // this.nextStep();
      }

      if (tipoDiCliente === "esistente") {
        this.currentStep = 1;
        this.isClienteSelezionatoStep = false;
        this.model.cliente_id = null;
      }
    },

    // ------------------------------------------
    // CLIENTE ESISTENTE SELEZIONATO
    // ------------------------------------------
    async onStep_1_ClienteEsistenteSelezionato({ cliente, veicolo }) {
      this.loading = true;
      try {
        const fastQuotationModel = await this.ottieniDatiDaTeoFormatoFastQuotationFlow(
          cliente[0]?.cliente_id,
          veicolo[0]?.veicolo_id ?? null
        );
        _;

        this.model.quotation_request = {
          ...this.model.quotation_request,
          ...fastQuotationModel,
        };

        /** Avanza allo step 2 */
        this.currentStep = 2;
        /** Abilita il passaggio allo step successivo */
        this.isClienteSelezionatoStep = true;
        /** Assegno i cliente id per la querystring */
        this.model.cliente_id = cliente[0]?.cliente_id;
      } catch (error) {
        console.error("Errore durante il caricamento dei dati:", error);
        // Opzionale: mostra un messaggio di errore all'utente
        this.$emit("error", "Errore durante il caricamento dei dati del cliente");
      } finally {
        this.loading = false;
      }
    },

    // ------------------------------------------
    // COMPILA IL FORM QUIXA CON DATI ESISTENTI
    // ------------------------------------------

    /**
     * Da un cliente e un veicolo memorizzati in teo,
     * prende i dati per compilare il form Quixa
     * La Risposta ha il formato adatto per una FastQuotationFlow request
     * @param cliente_id
     * @param veicolo_id
     */
    async ottieniDatiDaTeoFormatoFastQuotationFlow(cliente_id, veicolo_id) {
      this.loading = true;
      this.messageBag = {};
      try {
        const _response = await Api.post(
          process.env.VUE_APP_API_URL +
            "/api/adapter/quixa/clienteVeicolo/FastQuotationFlow",
          {
            cliente_id: cliente_id,
            veicolo_id: veicolo_id,
          }
        );

        return _response.data;
      } catch (err) {
        if (err?.response?.data) {
          // TODO: DA FARE Centralizzare i messaggi di errore con vuex
          this.messageBag.message = err.response.data.message;
          this.messageBag.errors = transformErrors(err.response.data.errors);
          this.$forceUpdate();

          console.log("submitQuotationData error");
        } else {
          this.messageBag = errorCallback(err);

          console.log("submitQuotationData 500 !!! ", err);
        }
      }
      this.loading = false;
    },

    // async trasformaQuotationInProposal() {
    //     this.loading = true;
    //     try {
    //         // Qui inserire la logica di salvataggio
    //         this.$emit("quotazione-confermata", this.model);
    //     } catch (error) {
    //         console.error("Errore durante il salvataggio:", error);
    //     } finally {
    //         this.loading = false;
    //     }
    // },

    async trasformaQuotationInProposal(quotationNumber, sendMailToClient = false) {
      this.loading = true; // Imposta lo stato di caricamento
      this.messageBag = {}; // Resetta eventuali messaggi precedenti

      try {
        // Dati da inviare nella richiesta
        const proposalData = {
          proposalData: {
            quotationNumber: quotationNumber, // Numero di quotazione
            sendMailToClient: sendMailToClient, // Imposta true o false
          },
        };

        // Chiamata API per inviare la proposta
        const response = await Api.post(
          `${process.env.VUE_APP_API_URL}/api/quixa/Proposal`,
          proposalData
        );

        console.log("Proposta inviata con successo:", response.data);
        // Gestisci la risposta di successo, ad esempio aggiornando il modello o mostrando un messaggio
      } catch (error) {
        this.messageBag = {
          message: "Errore durante l'invio della proposta.",
          errors: transformErrors(error.response?.data?.errors) || {},
        };
        this.$forceUpdate(); // Forza l'aggiornamento della vista
        console.error("Errore durante l'invio della proposta:", this.messageBag.errors);
      } finally {
        this.loading = false; // Ripristina lo stato di caricamento
      }
    },

    async loadQuotation(id) {
      this.loading = true;
      try {
        const response = await Api.get(
          `${process.env.VUE_APP_API_URL}/api/quixa/FastQuotationFlow/${id}`
        );

        this.model = response.data.data;
        this.isClienteSelezionatoStep = true;
      } catch (error) {
        console.error("Errore nel caricamento della quotazione:", error);
        this.messageBag = errorCallback(error);
      } finally {
        this.loading = false;
      }
    },

    async updateQuotation() {
      this.loading = true;
      this.messageBag = {};
      try {
        const response = await Api.put(
          `${process.env.VUE_APP_API_URL}/api/quixa/FastQuotationFlow/${this.quotationId}?cliente_id=${this.model?.cliente_id}`,
          this.requestHTTP
        );
        console.log(
          "Aggiornamento riuscito: cliente_id " + `${this.model?.cliente_id}`,
          response.data
        );
      } catch (error) {
        this.messageBag = {
          message: "Errore durante l'aggiornamento della quotazione.",
          errors: transformErrors(error.response?.data?.errors) || {},
        };
        this.$forceUpdate(); // Forza l'aggiornamento della vista
        console.error("Errore durante l'aggiornamento:", this.messageBag.errors);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
    requestHTTP() {
      // Clona profondamente l'oggetto request
      const clonedRequest = JSON.parse(JSON.stringify(this.model?.quotation_request));

      // Applica le trasformazioni al clone
      castToBoolean(clonedRequest);
      replaceValueContact(clonedRequest);

      // Ritorna l'oggetto clonato e trasformato
      return clonedRequest;
    },
  },
  async mounted() {
    // await this.initializeModel();
  },
  watch: {
    currentStep(newVal) {
      this.$emit("step-changed", newVal);
    },
  },
  async created() {
    // Controlla se siamo in modalità modifica verificando il route param
    const quotationId = this.$route.params.id;
    if (quotationId) {
      this.quotationId = quotationId;
      await this.loadQuotation(quotationId);
    }
  },
};

/**


*/
</script>

<style scoped>
.v-stepper {
  box-shadow: none;
}

.header-form-tab {
  margin-bottom: 1rem;
  color: var(--v-primary-base);
}
</style>
